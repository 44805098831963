/* 添加一些基本样式 */
body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    min-height: 100vh; /* 使用 min-height 替代 height，确保内容自适应高度 */
    background-color: #f4f4f9;
}

.App {
    width: 90%;
    /*max-width: 1440px;*/
    margin: 0 auto; /* 使用 margin: 0 auto 替代 flex 布局，确保内容居中且自适应宽度 */
    background-color: #fff;
    padding: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 20px; /* 添加一些间距 */
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    padding: 6.8px; /* 减少 padding 值以减少行间间隔为原来的85% */
    text-align: left;
}

th {
    background-color: #f8f8f8;
    border-bottom: 2px solid #ddd;
}

.pagination {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.pagination button {
    padding: 6px 12px;
    margin-right: 5px;
    cursor: pointer;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination input {
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.pagination select {
    padding: 6px;
    border: 1px solid #ddd;
    border-radius: 4px;
}